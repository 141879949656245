import React from "react";
import MainNavbar from "components/Navbars/MainNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Index() {
  return (
    <>
      <MainNavbar fixed />
      <section className="header relative items-center flex h-screen max-h-850px dark bg-dark text-white">
        <div className="container mx-auto items-center flex flex-wrap">
          <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
            <div className="pt-32 md:pt-0">
              <h2 className="font-semibold text-4xl">
                Welcome to Terrazas de Guacuco!
              </h2>
              <p className="mt-4 text-lg leading-relaxed pb-6">
                Come and enjoy an unforgettable vacation in our apartment with an ocean view.
                In front of Guacuco beach, on the beautiful Margarita Island.
                Our apartment is comfortably decorated and equipped with everything you need to feel at home during your stay.
                In addition, you will find a restaurant, a bodegón, and a massage parlor at your disposal, we hope to see you soon in our home away from home!
                The development has 24-hour security.
              </p>
            </div>
          </div>
          <div className="w-full md:w-4/12 lg:w-6/12 xl:w-6/12 px-4 flex justify-center mb-6">
            <img
              className="max-w-full md:max-h-860px"
              src="https://hotelesenmargarita.com/images/Maloka/Hotel_Boutique_Maloka_1.jpg"
              alt="Background Image"
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
