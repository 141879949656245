import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import MainNavbar from "components/Navbars/MainNavbar.js";
import FooterSmall from "components/Footers/FooterSmall.js";
import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import ForgotPassword from "views/auth/ForgotPassword.js";
import EmailSent from "views/auth/EmailSent";
import ResetPassword from "views/auth/ResetPassword.js";
import PasswordChanged from "views/auth/PasswordChanged.js";
import VerifyEmail from "views/auth/VerifyEmail.js";

export default function Auth() {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');

  return (
    <>
      <MainNavbar />
      <main>
        <section className="relative w-full h-full py-10 min-h-screen-95">
          <div
            className="absolute top-0 w-full h-full dark bg-dark bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url('https://images8.alphacoders.com/541/thumb-1920-541025.jpg')"
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 dark bg-dark"
            ></span>
          </div>
          <Switch>
            <Route path="/auth/verify-email/:id/:hash" exact component={VerifyEmail} />
            {token && role ? <Redirect to="/dashboard" /> : ''}
            <Route path="/auth/login" exact component={Login} />
            <Route path="/auth/register" exact component={Register} />
            <Route path="/auth/forgot-password" exact component={ForgotPassword} />
            <Route path="/auth/email-sent" exact component={EmailSent} />
            <Route path="/auth/reset-password/:token" exact component={ResetPassword} />
            <Route path="/auth/password-changed" exact component={PasswordChanged} />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
