import React from "react";
import { useState } from 'react';
import MainNavbar from "components/Navbars/MainNavbar.js";
import Footer from "components/Footers/Footer.js";
import { toast } from 'react-toastify';

export default function AboutUs() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    toast.success('Query submitted successfully!');
    setName('');
    setEmail('');
    setMessage('');
  }

  return (
    <>
      <MainNavbar transparent />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage: `url('https://img10.naventcdn.com/avisos/13/00/50/73/91/60/1200x1200/31895623.jpg')`,
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 dark bg-dark"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full px-4 ml-auto mr-auto flex justify-between items-center">
                <h1 className="text-white font-semibold text-5xl self-start mr-4 w-6/12">
                  About Us
                </h1>
                <p className="text-white flex-1">
                  Welcome to our cozy 40 square meter apartment in the Terrazas de Guacuco!
                  This space features a bedroom with a 12,000 BTU air conditioner and a comfortable queen-size bed,
                  perfect for resting after a day of adventures. You will also find a bathroom with an electric water heater and unlimited water thanks to the building's desalination plant.
                  The living room has a sofa bed with two beds and a single sofa, allowing us to comfortably accommodate up to five people.
                  You will also find a modern fridge, a fully equipped kitchen, and a TV to enjoy your favorite shows.
                  The living room has a sofa bed with two beds and a single sofa, allowing us to comfortably accommodate up to five people.
                  But that's not all, our apartment is only a few minutes' walk from Guacuco Beach, considered one of the best on Margarita Island.
                  And if you love pools, don't miss the opportunity to visit the best pool on the island, which is just a few minutes from our building.
                  Don't wait any longer and book your stay in our home away from home now!
                </p>
              </div>
            </div>
          </div>
        </div>

        <section className="pb-20 bg-blueGray-200 -mt-48">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-18 pt-16 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg bg-lightBlue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Facilities</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Air Conditioning<br />
                      Kitchen<br />
                      TV<br />
                      Washing Machine<br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:pt-18 pt-16 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Activities & Events</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Beach Games<br />
                      Shared Pool<br />
                      Concerts<br />
                      Fitness Sessions<br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:pt-18 pt-16 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg bg-emerald-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Security</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      24x7 Security<br />
                      CCTV<br />
                      Alarms<br />
                      Emergency Services<br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </main>
      <Footer />
    </>
  );
}
