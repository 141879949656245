import React from "react";

export default function Footer() {
  return (
    <>
      <footer className="relative dark bg-dark pt-4 pb-2">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left items-center">
            <div className="w-full lg:w-6/12 px-4">
              <div className="mt-6 mb-6">
                <button
                  className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fa fa-at"></i>
                </button>
                <button
                  className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-facebook-square"></i>
                </button>
                <button
                  className="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-twitter"></i>
                </button>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap md:justify-between justify-center">
                <div className="w-full px-4 mx-auto text-right">
                  <div className="text-sm text-blueGray-500 font-semibold py-1">
                    © {new Date().getFullYear()} Copyright Terrazas de Guacuco
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
