import React from "react";
import MainNavbar from "components/Navbars/MainNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Services() {
  return (
    <>
      <MainNavbar transparent />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage: `url('https://img10.naventcdn.com/avisos/13/00/50/73/91/60/1200x1200/31895623.jpg')`,
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 dark bg-dark"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full px-4 ml-auto mr-auto flex justify-between items-center">
                <h1 className="text-white font-semibold text-5xl self-start mr-4 w-6/12">
                  Our Services
                </h1>
                <p className="text-white flex-1">
                  You will find a restaurant, a bodegón, and a massage parlor at your disposal,
                  we hope to see you soon in our home away from home! The development has 24-hour security.
                  You will also find a bathroom with an electric water heater and unlimited water thanks to the building's desalination plant.
                  You will also find a modern fridge, a fully equipped kitchen, and a TV to enjoy your favorite shows.
                  In addition, the apartment has fiber optic Internet and WiFi so you can stay connected during your stay.
                  If you want to wash your clothes, don't worry, the apartment has a washing machine and dryer that will have your clothes clean and fresh in just 45 minutes.
                  And if you want to enjoy a pleasant view, the balcony is the perfect spot.
                  You will be able to enjoy a wide variety of facilities within the complex.
                  We have two pools, an Olympic-style pool where grills can be used and the main pool which features a churuata with a restaurant, bodegón, spa and nurse's office.
                  In addition, you will find tennis courts, a fronton court, criollo ball courts and a ping-pong table to keep you active during your stay.
                </p>
              </div>
            </div>
          </div>

        </div>

        <section className="pb-10 dark bg-dark -mt-48">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-18 pt-20 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg">
                  <div className="px-4 py-5 flex-auto">
                    <h6 className="text-xl font-semibold">
                      Infrastructure
                    </h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      We have good and affordable apartments for rent.
                      Our apartments can be leased out for long term or short term.
                      You can access all the facilities in the complex as part of our contract.
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:pt-18 pt-20 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg">
                  <div className="px-4 py-5 flex-auto">
                    <h6 className="text-xl font-semibold">
                      Eco-friendly
                    </h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      We provide residents with gardens for events.<br />
                      The gardens are opened 24/7 for residents.<br />
                      We rent out the gardens to thrid parties for events.<br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:pt-18 pt-20 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg">
                  <div className="px-4 py-5 flex-auto">
                    <h6 className="text-xl font-semibold">
                      Recreation
                    </h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Our pools can be leased out for external events.
                      We organize picnics, parties and swimming for pool enthusiast.
                      We provide pools for both residents and non residents.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
