import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/styles/tailwind.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AboutUs from 'views/AboutUs';
import Auth from 'layouts/Auth';
import Blog from 'views/Blog';
import ContactUs from 'views/ContactUs';
import Dashboard from 'layouts/Dashboard';
import Index from 'views/Index';
import Services from 'views/Services';
import { ToastContainer } from 'react-toastify';

ReactDOM.render(
  <BrowserRouter>
    <ToastContainer />
    <Switch>
      <Route path="/about_us" exact component={AboutUs} />
      <Route path="/auth" component={Auth} />
      <Route path="/blogs" exact component={Blog} />
      <Route path="/contact_us" exact component={ContactUs} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/services" exact component={Services} />
      <Route path="/" exact component={Index} />
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
